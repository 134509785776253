/*
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-12-11 23:41:31
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2020-12-17 09:47:31
 */
import wx from "weixin-js-sdk"
import {
    upload as uploadApi
} from "./api/utils"

import isIos from "./checkDevice"

/**
 * 选择图片
 * @param {*} len 选择图片的总张数
 */
export const chooseImgs = (len = 1) => {
    if (len <= 0) return Promise.resolve([])
    return new Promise((resolve, reject) => {
        wx.chooseImage({
            count: len, // 最多可以选择的图片张数，默认9
            sizeType: ['original', 'compressed'], // original 原图，compressed 压缩图，默认二者都有
            sourceType: ['album', 'camera'], // album 从相册选图，camera 使用相机，默认二者都有
            success: function (res) {
                // success
                resolve(res.localIds)
            },
            fail: function () {
                // fail
                reject()
            },
            complete: function () {
                // complete
            }
        })
    })
}

/**
 * 获取用于显示的图片
 * @param {*} imgs 图片列表
 */
export const getDisplayImgs = (imgs = []) => {
    if (!isIos()) return Promise.resolve(imgs)
    if (imgs.length <= 0) return Promise.resolve([])
    let result = [];
    let index = 0;
    return new Promise((resolve, reject) => {
        const getImgData = function () {
            if (index >= imgs.length) return resolve(result)
            wx.getLocalImgData({
                localId: imgs[index],
                success: function (res) {
                    result.push(res.localData);
                    index++;
                    getImgData()
                },
            });
        }
        getImgData()
    })
}



/**
 *  上传图片
 * @param {*} imgs 
 */
let storageImgs = {}; // 缓存上传的图片
export const uploadImgs = (imgs = []) => {
    if (imgs.length <= 0) return Promise.resolve([])
    let result = [];
    let index = 0;

    return new Promise((resolve, reject) => {

        const upload = function () {
            if (index >= imgs.length) return resolve(result)

            let uploadId = imgs[index];

            // 检查缓存
            if (storageImgs[uploadId]) {
                result.push(storageImgs[uploadId])
                index++
                upload()
            } else {
                wx.uploadImage({
                    localId: imgs[index],
                    success(res) {
                        uploadApi({
                            mediaId: res.serverId
                        }).then(res => {
                            let input = res.data.data.input
                            result.push(input);
                            storageImgs[uploadId] = input
                            index++
                            upload()
                        }).catch(res => [
                            reject(res)
                        ])
                    }
                })
            }
        }
        upload()

    })
}